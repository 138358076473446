var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"router-view-container"},[_c('div',{staticClass:"flex items-center space-x-4 mb-5"},[_c('vs-button',{attrs:{"border":"","size":"large"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('svg-icon',{attrs:{"icon-class":"arrow_left","className":"primary"}})],1),_c('h1',{staticClass:"page_title"},[_vm._v(_vm._s(_vm.$route.meta.title))]),_c('img',{staticClass:"w-32",attrs:{"src":require("@/assets/images/shipping/ezship_logo.gif"),"alt":"ezship"}})],1),(_vm.is_API_loaded)?_c('div',{staticClass:"space-y-10"},[_c('section',{staticClass:"wrap"},[_c('div',{staticClass:"leftBlock"},[_c('h2',{staticClass:"h2 sm:mt-4"},[_vm._v("物流參數設定")])]),_c('div',{staticClass:"rightBlock"},[_c('div',[_c('section',{staticClass:"grid grid-cols-1 gap-6"},[_c('div',[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("ezShip帳號 *")])]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","name":"ezShip帳號","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.setting.sid),callback:function ($$v) {_vm.$set(_vm.setting, "sid", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"setting.sid"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])])]),_c('hr'),_c('section',{staticClass:"wrap"},[_c('div',{staticClass:"leftBlock"},[_c('h2',{staticClass:"h2 sm:mt-4"},[_vm._v("運送方式")])]),_c('div',{staticClass:"rightBlock",staticStyle:{"padding":"16px 0"}},[_c('div',_vm._l((_vm.shippingList),function(item,index){return _c('collapse-item',{key:index,staticClass:"px-4",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"flex items-center space-x-2"},[_c('vs-checkbox',{attrs:{"success":""},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.checkbox[item.chkName]),callback:function ($$v) {_vm.$set(_vm.checkbox, item.chkName, $$v)},expression:"checkbox[item.chkName]"}}),_c('p',[_vm._v("超商店到店(全家/萊爾富/OK) "+_vm._s(item.cod))])],1)]},proxy:true}],null,true)},[_c('section',{staticClass:"py-4 pl-8 space-y-6"},[_c('section',[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("顯示的運送名稱")]),_c('p',{staticClass:"text-gray-400"},[_vm._v("※ 會顯示於結帳頁面上。")])]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","name":"顯示的運送名稱","vid":item.vid,"rules":((_vm.checkbox[item.chkName] ? 'required' : '') + "|max:30")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{attrs:{"maxlength":"30","placeholder":("超商店到店(全家/萊爾富/OK) " + (item.cod))},model:{value:(item.data.title),callback:function ($$v) {_vm.$set(item.data, "title", $$v)},expression:"item.data.title"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('section',[_c('h4',{staticClass:"h4 mb-1"},[_vm._v("運費")]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","name":"運費","vid":item.vid + 'cost',"rules":((_vm.checkbox[item.chkName] ? 'required' : '') + "|numeric|min_value:0")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex items-center space-x-2 w-full"},[_c('p',[_vm._v("NT$")]),_c('vs-input',{staticClass:"w-full",attrs:{"type":"number","placeholder":"0"},model:{value:(item.data.lpays),callback:function ($$v) {_vm.$set(item.data, "lpays", _vm._n($$v))},expression:"item.data.lpays"}})],1),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('section',[_c('div',{staticClass:"flex items-center justify-between mb-1"},[_c('h4',{staticClass:"h4"},[_vm._v("運送說明")]),_c('p',{staticClass:"text-gray-400"},[_vm._v("※ 會顯示於結帳頁面上。")])]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","name":"運送說明","vid":item.vid + 'textarea',"rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('myTextarea',{attrs:{"placeholder":"填寫顧客於結帳時的提示或說明","height":"100px","maxlength":"200"},model:{value:(item.data.note),callback:function ($$v) {_vm.$set(item.data, "note", $$v)},expression:"item.data.note"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])}),1)])]),_c('hr'),_c('div',{staticClass:"flex items-center justify-between"},[_c('router-link',{attrs:{"to":{ name: 'store_shipping' }}},[_c('vs-button',{attrs:{"size":"large","border":""}},[_vm._v("取消")])],1),_c('vs-button',{ref:"saveBtn",attrs:{"disabled":invalid,"size":"large"},on:{"click":function($event){return _vm.saveEdit(invalid)}}},[_vm._v("儲存")])],1)]):_c('div',{staticClass:"space-y-4"},_vm._l((2),function(item,index){return _c('section',{key:index,staticClass:"wrap"},[_c('div',{staticClass:"leftBlock"},[_c('skeleton',{staticClass:"mt-4",attrs:{"width":"100px"}}),_c('skeleton',{attrs:{"width":"200px","height":"10px"}}),_c('skeleton',{attrs:{"width":"200px","height":"10px"}}),_c('skeleton',{attrs:{"width":"200px","height":"10px"}})],1),_c('div',{staticClass:"rightBlock"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('skeleton',{attrs:{"width":"100px"}}),_c('skeleton',{attrs:{"width":"100px"}})],1),_c('div',{staticClass:"flex items-start flex-col space-y-2"},_vm._l((3),function(item,index){return _c('skeleton',{key:index,attrs:{"width":"200px"}})}),1)])])}),0)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }