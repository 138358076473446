<template>
  <div class="w-full collapse-item">
    <!-- title -->
    <div class="w-full flex items-center justify-between cursor-pointer h-10 relative" @click="handleClick">
      <slot name="title"></slot>
      <svg-icon
        icon-class="triangle"
        className="gray-dark"
        style="width: 0.8rem; height: 0.8rem"
        class="transform transition-transform"
        :class="[active ? '' : '-rotate-90']"
      ></svg-icon>
    </div>
    <!-- content -->
    <div ref="content" class="overflow-hidden transition-all duration-300 ease-in-out" style="max-height: 0">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'collapse-item',
  data() {
    return {
      active: false,
      contentHeight: null,
    }
  },
  // props: {
  //   active: {
  //     type: Boolean,
  //     default: false,
  //   },
  // },
  mounted() {
    // this.contentHeight = window.getComputedStyle(this.$refs.content).height
    // this.contentStyle.height = this.contentHeight
  },
  watch: {
    active: {
      handler: function (val) {
        setTimeout(() => {
          let content = this.$refs.content

          if (val) {
            content.style.maxHeight = '3000px'
            // content.style.height = '100%'
          } else {
            content.style.maxHeight = '0'
          }
        }, 0)
      },
      immediate: true,
    },
  },
  methods: {
    handleClick() {
      // let status
      // this.active ? (status = false) : (status = true)
      // this.$emit('change', status)
      this.active = !this.active

      // let content = this.$refs.content

      // this.active ? (content.style.height = this.contentHeight) : (content.style.height = '0')
    },
  },
}
</script>

<style scoped>
.collapse-item {
  border-bottom: 1px solid var(--gray-lightest);
}

.collapse-item:last-child {
  /* border-bottom: 1px solid var(--gray-lightest); */
  border-bottom: none;
}
</style>
