ez
<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="router-view-container">
        <div class="flex items-center space-x-4 mb-5">
          <vs-button @click="$router.go(-1)" border size="large">
            <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
          </vs-button>
          <h1 class="page_title">{{ $route.meta.title }}</h1>
          <img class="w-32" src="@/assets/images/shipping/ezship_logo.gif" alt="ezship" />
        </div>

        <div v-if="is_API_loaded" class="space-y-10">
          <section class="wrap">
            <div class="leftBlock">
              <h2 class="h2 sm:mt-4">物流參數設定</h2>
            </div>
            <div class="rightBlock">
              <div>
                <section class="grid grid-cols-1 gap-6">
                  <div>
                    <div class="flex items-center justify-between mb-1">
                      <h4 class="h4">ezShip帳號 *</h4>
                    </div>
                    <ValidationProvider tag="div" class="w-full relative" name="ezShip帳號" rules="required" v-slot="{ errors }">
                      <vs-input v-model.trim="setting.sid"></vs-input>
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                </section>
              </div>
            </div>
          </section>
          <hr />

          <section class="wrap">
            <div class="leftBlock">
              <h2 class="h2 sm:mt-4">運送方式</h2>
            </div>
            <div class="rightBlock" style="padding: 16px 0">
              <div>
                <collapse-item class="px-4" v-for="(item, index) in shippingList" :key="index">
                  <template #title>
                    <div class="flex items-center space-x-2">
                      <vs-checkbox success v-model="checkbox[item.chkName]" @click.stop=""></vs-checkbox>
                      <p>超商店到店(全家/萊爾富/OK) {{ item.cod }}</p>
                    </div>
                  </template>
                  <section class="py-4 pl-8 space-y-6">
                    <section>
                      <div class="flex items-center justify-between mb-1">
                        <h4 class="h4">顯示的運送名稱</h4>
                        <p class="text-gray-400">※ 會顯示於結帳頁面上。</p>
                      </div>
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        name="顯示的運送名稱"
                        :vid="item.vid"
                        :rules="`${checkbox[item.chkName] ? 'required' : ''}|max:30`"
                        v-slot="{ errors }"
                      >
                        <vs-input
                          v-model="item.data.title"
                          maxlength="30"
                          :placeholder="`超商店到店(全家/萊爾富/OK) ${item.cod}`"
                        ></vs-input>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </section>

                    <section>
                      <h4 class="h4 mb-1">運費</h4>
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        name="運費"
                        :vid="item.vid + 'cost'"
                        :rules="`${checkbox[item.chkName] ? 'required' : ''}|numeric|min_value:0`"
                        v-slot="{ errors }"
                      >
                        <div class="flex items-center space-x-2 w-full">
                          <p>NT$</p>
                          <vs-input class="w-full" type="number" v-model.number="item.data.lpays" placeholder="0"></vs-input>
                        </div>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </section>

                    <section>
                      <div class="flex items-center justify-between mb-1">
                        <h4 class="h4">運送說明</h4>
                        <p class="text-gray-400">※ 會顯示於結帳頁面上。</p>
                      </div>
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        name="運送說明"
                        :vid="item.vid + 'textarea'"
                        rules="max:200"
                        v-slot="{ errors }"
                      >
                        <myTextarea
                          placeholder="填寫顧客於結帳時的提示或說明"
                          height="100px"
                          v-model="item.data.note"
                          maxlength="200"
                        ></myTextarea>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </section>
                  </section>
                </collapse-item>
              </div>
            </div>
          </section>
          <hr />
          <div class="flex items-center justify-between">
            <router-link :to="{ name: 'store_shipping' }">
              <vs-button size="large" border>取消</vs-button>
            </router-link>

            <vs-button :disabled="invalid" size="large" ref="saveBtn" @click="saveEdit(invalid)">儲存</vs-button>
          </div>
        </div>

        <!-- 骨架屏 -->
        <div class="space-y-4" v-else>
          <section v-for="(item, index) in 2" :key="index" class="wrap">
            <div class="leftBlock">
              <skeleton class="mt-4" width="100px" />
              <skeleton width="200px" height="10px" />
              <skeleton width="200px" height="10px" />
              <skeleton width="200px" height="10px" />
            </div>
            <div class="rightBlock">
              <div class="flex items-center justify-between">
                <skeleton width="100px" />
                <skeleton width="100px" />
              </div>
              <div class="flex items-start flex-col space-y-2">
                <skeleton v-for="(item, index) in 3" :key="index" width="200px" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import collapseItem from '@/components/ui_components/collapse-item'
// import shipping from '@/assets/mixins/shipping.js'
export default {
  name: 'ecpay-shipping',
  // mixins: [shipping],
  components: {
    collapseItem,
  },
  data() {
    return {
      is_API_loaded: false,
      setting: {},
      checkbox: {
        ezShip: false,
        ezShip_COD: false,
      },
      shippingList: {
        ezShip: {
          logisticMixin_ID: 2,
          vid: '3',
          cod: '純取貨',
          chkName: 'ezShip',
          data: {
            pterms: 0,
            shit: '台灣 Taiwan',
            land: 0,
            logistics: 0,
            title: '超商店到店(全家/萊爾富/OK) 純取貨',
            temp: 1,
            lpays: 60,
            note: '',
          },
        },
        ezShip_COD: {
          logisticMixin_ID: 2,
          vid: '4',
          cod: '取貨付款',
          chkName: 'ezShip_COD',
          data: {
            pterms: 6,
            shit: '台灣 Taiwan',
            land: 0,
            logistics: 0,
            title: '超商店到店(全家/萊爾富/OK) 取貨付款',
            temp: 1,
            lpays: 60,
            note: '',
          },
        },
      },
    }
  },
  created() {
    this.loadSetting()
  },
  methods: {
    // 4.4.2.6 物流第三方資料-讀取
    loadSetting() {
      this.$axios({
        url: 'front/set/app/getLogisticsThirdData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          lthird: 2, // 讀取ezship
        },
      }).then((res) => {
        if (res.data.Success) {
          const allDataAry = res.data.Data.alldata

          allDataAry.forEach((item) => {
            // ezship
            if (item.logistics === 0) {
              if (item.pterms === 0) {
                // 純取貨
                this.shippingList.ezShip.data = item
                this.checkbox.ezShip = true
              } else if (item.pterms === 6) {
                // 取貨付款
                this.shippingList.ezShip_COD.data = item
                this.checkbox.ezShip_COD = true
              }
            }
          })

          this.setting = res.data.Data
          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },

    // 4.4.2.7 物流第三方資料-修改
    saveEdit(invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      let allData = []
      for (let key in this.checkbox) {
        if (this.checkbox[key]) allData.push(this.shippingList[key].data)
      }

      this.$axios({
        url: 'front/set/app/uLogisticsThirdData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          lthird: 2, // ezship
          sid: this.setting.sid,
          hashkey: this.setting.hashkey,
          hashiv: this.setting.hashiv,
          alldata: JSON.stringify(allData),
        },
      }).then((res) => {
        loading.close()
        if (res.data.Success) {
          this.loadSetting()
          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  @apply flex flex-col sm:flex-row sm:space-x-10 space-y-4 sm:space-y-0;
}

.leftBlock {
  @apply w-full sm:w-1/3 space-y-4 flex-shrink-0;
}

.rightBlock {
  @apply flex-grow h-fit bg-white p-4 rounded-xl space-y-6;
}

// .list_item {
//   @apply ml-0 relative px-4;

//   &::before {
//     content: '';
//     position: absolute;
//     width: 7px;
//     height: 7px;
//     left: 0px;
//     top: 50%;
//     transform: translateY(-50%);
//     border-radius: 100%;
//     background: var(--gray-border);
//   }
// }
</style>
